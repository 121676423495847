@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
}

.container {
  width: 100%;
  height: 100%;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 390px !important;
  font-family: inherit !important;
}

.MuiTableContainer-root {
  height: 80%;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  font-family: "Poppins" !important;
  text-transform: none !important;
}

.multiple_wrap {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2px;
}

.multiple_wrap input {
  width: 88%;
}

.email_wrap {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}

.email {
  font-size: 13px;
  background-color: lightgreen;
  border-radius: 100px;
  padding: 10px 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.email span {
  position: absolute;
  top: -10px;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
