.auth-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(to left, #00000095, #000000c2);
}

.form {
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 35px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  animation: pop 1s 0.1s linear forwards;
}

@keyframes pop {
  0% {
    transform: scale(0.8) translateY(-1000px);
    opacity: 0;
  }
  50% {
    transform: scale(1.1) translateY(-100px);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.auth-logo {
  overflow: hidden;
}

.auth-logo img {
  /* width: 100%; */
  height: 100px;
}

.form-title {
  font-size: 25px;
  font-weight: 600;
  /* margin-top: 50px; */
}

.form input {
  font-family: inherit;
  width: 90%;
  margin: 12px 0;
  padding: 10px;
  border: 2px solid #707070;
  outline: none;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
}

.form button {
  width: 90%;
  margin-top: 12px;
  padding: 10px;
  border: none;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
  background-image: linear-gradient(to left, #43cea2, #045de9);
  color: #fff;
  font-size: 15px;
}

