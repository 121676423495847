.qr-grid {
  width: 1200px;
  /* height: 391px; */
  background-color: #ffff;
  display: grid;
  padding-left: 25px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 268px));
  grid-row-gap: 44.3px;
  grid-column-gap: 30.2px;
  /* justify-content: space-between; */
  overflow: hidden;
  padding-top: 22.15px;
  padding-bottom: 35px;
  justify-content: flex-start;
  background-color: #067eee;
  overflow: visible;
}
.qr-grid-vehicle {
  align-items: center;
  width: 1200px;
  /* height: 391px; */
  background-color: #ffff;
  display: grid;
  grid-column-gap: 25.2px;

  padding-left: 37px;
  padding-right: 15px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 268px));
  grid-row-gap: 39px;
  /* padding-top: 19.5px; */
  /* justify-content: space-between; */
  overflow: hidden;
  padding-top: 19.5px;
  padding-bottom: 19.5px;
  justify-content: flex-start;
  background-color: #067eee !important;
  overflow: visible;
}
@page {
  size: auto;
  margin: 0mm;
  border: none;
}

@media print {
  .qr-wrap {
    border: none !important;
  }
  .qr-grid-vehicle {
    background-color: #067eee !important;
  }
}

.qr-wrap {
  width: 258px;
  height: 380px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  position: relative;
  padding-bottom: 40px;
  background-color: #fff;
}
.qr-wrap-vehicle {
  width: 240px;
  height: 300px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-radius: 25px; */
  /* border: 14px solid #067eee; */
  position: relative;
  padding-bottom: 40px;
  background-color: #fff;
}

.borderImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.dot {
  width: 6px;
  display: flex;
  height: 6px;
  border-radius: 50%;
  background-color: #000;
}

.qr-val {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
  width: 90%;
  background-color: #edf3fe;
  border-radius: 6px;
  padding: 0 10px;

  display: flex;
  align-items: center;
  padding: 6px 10px;
  position: relative;
  z-index: 11;
}

.qrcode-wrap {
  width: 200px;
  height: 180px;
  padding: 16px;
  position: absolute;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  background-color: #edf3fe;
  z-index: 10;
  top: 13.5%;
  /* overflow: hidden; */
}
.qrcode {
  position: relative;
  z-index: 11;
}
.qr-footer {
  display: flex;
  align-items: center;
}

.qr-footer {
  color: #000;
  font-weight: 600;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.qr-note-vehicle {
  color: #000;
  font-weight: 500;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* margin-top: 1px; */
}
.qr-note-vehicle p {
  display: flex;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  /* letter-spacing: 1.6px; */
  text-align: center;
}

.qr-footer p {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  /* letter-spacing: 1.6px; */
  text-align: center;
}
.qr-footer-img {
  width: 20px;
}
.qr-notes {
  padding: 0 15px;
}

.qr-notes li {
  font-size: 9px;
  margin: 6px 0;
  font-weight: 500;
  color: #312f2f;
}
.footer-logo {
  height: 12%;
  /* width: 100%; */
  /* display: flex; */
  aspect-ratio: auto;
  margin-bottom: 2px;
  position: absolute;
  bottom: 2px;
}

.qr-id {
  position: absolute;
  bottom: 10px;
  right: 17px;
  font-weight: 400;
  color: #7b7b7b;
  font-size: 8px;
}

.gap {
  grid-column: 1/-1;
  height: 10px;
}

.btdcqr-footer {
  list-style-type: none;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}

