.left {
  width: 20%;
  height: 100%;
  background-color: #fff;
  border-right-width: 1px;
  border-right-color: lightgrey;
  border-right-style: solid;
  padding: 30px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.left-logo {
  width: 60%;
}
.left-menu {
  width: 100%;
  flex: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item-wrap {
  width: 80%;
}
.left-menu_item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  padding: 15px 0;
}

.left-menu_item:hover {
  transform: scale(1.02);
}

.left-menu_item p {
  margin-left: 10px;
  font-weight: 500;
  color: #030303;
}

.left-profile {
  flex: 0.1;
}

.right {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.adminText{
  position: absolute;
  top: 70px;
  left: 150px;
  font-family: cursive;
}
